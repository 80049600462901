import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <p>Now that you are familiar with both clinical trials, think about why one trial might be a better experiment than the other.</p>
          <p>Be ready to share your ideas in a class discussion.</p>
          <p>Do you want to add anything else to the list you made earlier about good experimental design?</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
